import './Projects.css';
import imgAstro from '../assets/projects/astro-sl.jpg';
import imgLiskland from '../assets/projects/projects-px-wp_sl.jpg';
import imgGallery from '../assets/projects/vrgallery-sl.jpg';
import imgEmeralds from '../assets/projects/emeralds-sl.jpg';
import imgBackrooms from '../assets/projects/backrooms-sl.jpg';
import imgNerf from '../assets/projects/nerf-sl.jpg';
import imgSbeep from '../assets/projects/sbeep-sl.jpg';
import imgRek from '../assets/projects/rekenen-sl.jpg';
import heroVideos from '../components/ProjectsVideos';
import { useState } from 'react';

const Projects = () => {
  const [heroVid, setHeroVid] = useState(heroVideos[5]);

  const changeHeroImage = (imgId: number) => {
    console.log(heroVid);

    setHeroVid(heroVideos[imgId - 1]);
  };

  return (
    <div className="projectsContainer" id="projects">
      <div className="projects">
        <h1>Recent Projects</h1>
      </div>
      <div className="projectItems">
        <div className="projectItem">
          <img src={imgNerf} className="projectImage" alt="NERF Technology" onMouseOver={() => changeHeroImage(6)} />
          <h2>NERF Technology</h2>
          <p>
            The latest Neural Radiance Fields technology allows me to create 3D models of physical objects, for example
            statues, preserving them for the future. It also allows for creative freedom in video production, enabling
            almost impossible shots.
            <br />
            <i>Skills: NERF, 3D modelling, preservation, videography.</i>
            <br />
          </p>
        </div>
        <div className="projectItem">
          <img src={imgAstro} className="projectImage" alt="Astro VR Rocks" onMouseOver={() => changeHeroImage(1)} />
          <h2>Astro VR Rocks</h2>
          <p>
            Astro VR Rocks allows users with a VR headset to see the space rocks collected by NASA up-close.
            <br />
            <i>
              Skills: VR, WebXR, TypeScript/ JavaScript, Blender, React Three Fiber, -XR, -Drei, three-js, Ubuntu
              server, NodeJS, PM2 and Express.
            </i>
          </p>
        </div>
        <div className="projectItem">
          <img
            src={imgEmeralds}
            className="projectImage"
            alt="Emeralds Catcher"
            onMouseOver={() => changeHeroImage(2)}
          />
          <h2>Emeralds Catcher</h2>
          <p>
            Emeralds Catcher is a fast paced VR game, catch as many emeralds as you can and avoid the bombs. Use the
            right thumbstick to move.
            <br />
            <i>
              Skills: VR, WebXR, TypeScript/ JavaScript, Blender, React Three Fiber, -XR, -Drei, three-js, Ubuntu
              server, NodeJS.
            </i>
            <br />
            🌐{' '}
            <a href="https://emeralds.nxdec.io" target="_blank" rel="noreferrer">
              emeralds.nxdec.io
            </a>
          </p>
        </div>
        <div className="projectItem">
          <img src={imgGallery} className="projectImage" alt="VR Gallery" onMouseOver={() => changeHeroImage(3)} />
          <h2>VR Gallery</h2>
          <p>
            Walk around in a virtual museum in your own home. Luma AI was used to create photorealistic models of real
            life objects such as the plant and bananas.
            <br />
            <i>
              Skills: VR, WebXR, TypeScript/ JavaScript, Luma AI, Blender, React Three Fiber, -XR, -Drei, three-js,
              Ubuntu server, NodeJS.
            </i>
            <br />
            🌐{' '}
            <a href="https://gallery.nxdec.io" target="_blank" rel="noreferrer">
              gallery.nxdec.io
            </a>
          </p>
        </div>
        <div className="projectItem">
          <img
            src={imgLiskland}
            className="projectImage"
            alt="Pixelated Nature Blog"
            onMouseOver={() => changeHeroImage(4)}
          />
          <h2>Pixelated Nature Blog</h2>
          <p>
            Blog created with WordPress, used to promote and announce new designs in the Pixelated Nature Zazzle store.
            <br />
            <i>
              Skills: LiteSpeed One-Click WordPress Server, Ubuntu server, articles written with AI assistant, optimized
              for google with SEO plugin.
            </i>
            🌐{' '}
            <a href="https://pixelatednature.com/" target="_blank" rel="noreferrer">
              pixelatednature.com
            </a>
          </p>
        </div>
        <div className="projectItem">
          <img src={imgBackrooms} className="projectImage" alt="The Backrooms" onMouseOver={() => changeHeroImage(5)} />
          <h2>The Backrooms</h2>
          <p>
            Explore the backrooms in virtual reality, based on a popular urban legend.
            <br />
            <i>
              Skills: VR, WebXR, TypeScript/ JavaScript, Blender, React Three Fiber, -XR, -Drei, three-js, Ubuntu
              server, NodeJS, PM2 and Express.
            </i>
            <br />
            🌐{' '}
            <a href="https://backrooms.nxdec.io" target="_blank" rel="noreferrer">
              backrooms.nxdec.io
            </a>
          </p>
        </div>
        <div className="projectItem">
          <img src={imgSbeep} className="projectImage" alt="Speed Beep" onMouseOver={() => changeHeroImage(8)} />
          <h2>Speed Beep</h2>
          <p>
            Simple reaction game for the visually impaired (Dutch).
            <br />
            <i>Skills: HTML, CSS, JavaScript, Web Speech API, Azure Cloud Static Web App, NodeJS.</i>
            <br />
            🌐{' '}
            <a href="https://speedbeep.site" target="_blank" rel="noreferrer">
              speedbeep.site
            </a>
          </p>
        </div>
        <div className="projectItem">
          <img src={imgRek} className="projectImage" alt="Speed Beep" onMouseOver={() => changeHeroImage(7)} />
          <h2>Rekenen</h2>
          <p>
            Practice simple calculations such as addition, subtraction and multiplying (Dutch).
            <br />
            <i>Skills: React, TypeScript/ JavaScript, Azure Cloud Static Web App, NodeJS.</i>
            <br />
            🌐{' '}
            <a href="https://rekenen.lol" target="_blank" rel="noreferrer">
              rekenen.lol
            </a>
          </p>
        </div>
      </div>
      <div className="projectHero">
        <video autoPlay muted loop playsInline preload="auto" src={heroVid} />
      </div>
      <div className="projectsInfo">
        All VR apps are programmed in Typescript and are using the WebXR Device API. This allows the app to be deployed
        like a website and run on multiple headsets with no installation needed. 3D models are created in Blender except
        where indicated. NXDEC.io is using NGINX web server with multiple subdomains and certbot for a secure HTTPS
        connection. I'm able to handle a wide range of web development projects.
      </div>
    </div>
  );
};

export default Projects;
