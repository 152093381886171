import HeroVideo1 from '../assets/projects/projects-astro.mp4';
import HeroVideo2 from '../assets/projects/projects-emeralds.mp4';
import HeroVideo3 from '../assets/projects/projects-gallery.mp4';
import HeroVideo4 from '../assets/projects/projects-px-wp.mp4';
import HeroVideo5 from '../assets/projects/projects-backrooms.mp4';
import HeroVideo6 from '../assets/projects/projects-nerf.mp4';
import HeroVideo7 from '../assets/projects/projects-rekenen.mp4';
import HeroVideo8 from '../assets/projects/projects-sbeep.mp4';

const heroVideos = [HeroVideo1, HeroVideo2, HeroVideo3, HeroVideo4, HeroVideo5, HeroVideo6, HeroVideo7, HeroVideo8];
export default heroVideos;
